<template>
  <div class="table_wrapper">
    <v-container>
      <v-row v-if="overlay == false">
        <v-col cols="12">
          <h5 class="red--text">Offers</h5>
        </v-col>
        <v-col cols="12">
          <v-simple-table v-if="offered.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Product Name
                  </th>
                  <th class="text-left">
                    Product Price
                  </th>
                  <th class="text-left">
                    Product Detail
                  </th>
                  <th class="text-left">
                    Product Model Number
                  </th>
                  <th class="text-left">
                    Product Quantity
                  </th>
                  <th class="text-left">
                    Files
                  </th>
                  <th class="text-left">
                    Created at
                  </th>
                  <th class="text-left">
                    Updated at
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, b) in offered" :key="b">
                  <td v-for="(y, z) in x" :key="z">
                    <span v-if="z != 5">
                      {{ y }}
                    </span>
                    <span v-else>
                      <v-img
                        max-width="50"
                        max-height="50"
                        @click="(dialogImage = true), (dialogImageUrl = s)"
                        v-for="(s, i) in y"
                        :key="i"
                        :src="s"
                      ></v-img>
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12">
          <h5 class="red--text">Addinational Costs</h5>
        </v-col>
        <v-col cols="12">
          <v-simple-table v-if="cost.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Cost Name
                  </th>
                  <th class="text-left">
                    Cost Price
                  </th>
                  <th class="text-left">
                    Cost Detail
                  </th>
                  <th class="text-left">
                    Cost Quantity
                  </th>
                  <th class="text-left">
                    Created at
                  </th>
                  <th class="text-left">
                    Updated at
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, b) in cost" :key="b">
                  <td v-for="(y, z) in x" :key="z">
                    <span>
                      {{ y }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12">
          <h5 class="red--text">General Details</h5>
        </v-col>

        <v-col cols="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="form.offer_deadline"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.offer_deadline"
                label="Offer Deadline"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.offer_deadline" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(form.offer_deadline)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="4">
          <v-select
            label="Price Currency"
            item-text="name"
            item-value="id"
            v-model="form.currency.id"
            :items="currencies"
            :loading="loading"
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-select
            label="Accepted Payment Types"
            item-text="name"
            item-value="id"
            v-model="form.payment_type_id"
            :items="paymenttypes"
            :loading="loading"
          ></v-select>
        </v-col>

        <v-col cols="6">
          <v-layout>
            <v-col cols="8">
              <v-select
                label="Nearest Airport"
                item-text="name"
                item-value="id"
                v-model="form.airport_country_id"
                :items="countries"
                @change="getCities(1)"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="City"
                item-text="name"
                item-value="id"
                v-model="form.airport_city_id"
                :items="cities"
                :loading="loading"
              ></v-select>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="6">
          <v-layout>
            <v-col cols="8">
              <v-select
                label="Nearest Seaport"
                item-text="name"
                item-value="id"
                v-model="form.seaport_country_id"
                :items="countries"
                @change="getCities(2)"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="City"
                item-text="name"
                item-value="id"
                v-model="form.seaport_city_id"
                :items="cities2"
                :loading="loading"
              ></v-select>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="6">
          <v-select
            label="Shipping Terms"
            item-text="name"
            item-value="id"
            v-model="form.shipping_term_id"
            :items="shippingterms"
            :loading="loading"
          ></v-select>
        </v-col>

        <v-col cols="6">
          <v-select
            label="Shipping Types"
            item-text="name"
            item-value="id"
            :items="shippings"
            v-model="form.offered_shipping_type.id"
            :loading="loading"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="form.description"
            :value="form.description"
            :loading="loading"
            label="Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="overlay == false">
          <v-btn class="ml-3" color="primary" dark :to="'/rfq/edit/' + form.id">
            RFQ EDIT
          </v-btn>
          <v-btn
            class="ml-3"
            color="green"
            dark
            :disabled="dialog"
            :loading="dialog"
            v-on:click="saveAllData"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="p-3">
          Loading.. Please wait.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImage" width="600">
      <v-card>
        <v-img :src="dialogImageUrl"></v-img>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  name: "RfqOffersEdit",
  data() {
    return {
      moment: moment,
      overlay: true,
      menu: false,
      dialogImage: false,
      dialogImageUrl: "",
      dialog: true,
      loading: true,
      translations: [],
      categories: [],
      currencies: [],
      countries: [],
      cities: [],
      cities2: [],
      shippings: [],
      shippingterms: [],
      paymenttypes: [],
      units: [],
      files: [],
      form: null,
      offered: [],
      cost: [],
      rfqdetail: null,
      lock: false,
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    saveAllData: function() {
      this.dialog = true;
      this.form.id = Number(this.$route.params.id);

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "quotation/" +
            this.$route.params.id +
            "?api_token=" +
            this.token,
          this.form
        )
        .then((res) => {
          this.dialog = false;
          this.$router.push({
            name: "Rfq",
          });
        })
        .catch((err) => console.log(err));
    },
    getUnits: function() {
      let url = "https://gowawe.com/api/units";
      axios.get(url).then((res) => {
        const response = res.data.data;
        if (response.length > 0) {
          this.units = response;
        }
      });
    },
    getCurrencies: function() {
      let url = "https://dashboard.gowawe.com/getCurreny";
      axios.post(url).then((res) => {
        this.currencies = res.data;

        this.getCategories();
      });
    },

    getCategories: function() {
      let url = "https://gowawe.com/api/admin/category?api_token=" + this.token;
      axios.get(url).then((res) => {
        this.categories = res.data.data;

        this.getPaymentTypes();
      });
    },
    getShipping: function() {
      const fields = [
        {
          field: "lookup_type_id",
          op: "=",
          value: 2,
        },
      ];
      let url =
        "https://gowawe.com/api/admin/lookup/filter?page=1&per_page=100&api_token=" +
        this.token;
      axios
        .post(url, {
          fields: fields,
        })
        .then((res) => {
          res.data.data.data.forEach((item) => {
            this.shippings.push({
              id: item.id,
              name: item.name,
            });
          });

          this.getShippingTerms();
        });
    },
    getShippingTerms: function() {
      let url = "https://gowawe.com/api/en/shipping-terms";
      axios.get(url).then((res) => {
        const response = res.data.data;
        if (response && response.length > 0) {
          this.shippingterms = response;
        }

        this.getCurrencies();
      });
    },
    getCountry: function() {
      let url =
        "https://gowawe.com/api/admin/country/filter?page=1&per_page=1000&api_token=" +
        this.token;
      axios.post(url).then((res) => {
        res.data.data.data.forEach((item) => {
          this.countries.push({
            id: item.id,
            name: item.name,
          });

          this.getCities(null);
        });
      });
    },
    getPaymentTypes: function() {
      let url = "https://gowawe.com/api/admin/payment-types";
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            res.data.data.forEach((item) => {
              this.paymenttypes.push({
                id: item.id,
                name: item.name,
              });
            });
          }
          this.loading = false;
          this.overlay = false;
        })
        .catch((err) => {
          this.loading = false;
          this.overlay = false;
        });
    },
    getCities: function(type = null) {
      if (this.lock == false) {
        this.lock = true;

        if (type == 1 || type == null) {
          if (this.form.airport_country_id) {
            this.cities = [];
            let url =
              "https://gowawe.com/api/admin/country/" +
              this.form.airport_country_id +
              "/cities?api_token=" +
              this.token;
            axios.get(url).then((res) => {
              res.data.data.forEach((item) => {
                this.cities.push({
                  id: item.id,
                  name: item.name,
                });
              });
            });
          }
        }
        if (type == 2 || type == null) {
          if (this.form.seaport_country_id) {
            this.cities2 = [];
            let url =
              "https://gowawe.com/api/admin/country/" +
              this.form.seaport_country_id +
              "/cities?api_token=" +
              this.token;
            axios.get(url).then((res) => {
              res.data.data.forEach((item) => {
                this.cities2.push({
                  id: item.id,
                  name: item.name,
                });
              });
            });
          }
        }
        this.getShipping();
      }
    },
    getData: function(id) {
      let url =
        "https://gowawe.com/api/admin/quotation-offer/" +
        id +
        "&api_token=" +
        this.token;
      axios
        .get(url)
        .then((res) => {
          this.dialog = false;
          const resp = res.data;

          this.form = resp;
          this.rfqdetail = resp.quotation_request;
          this.form.payment_type_id = resp.payment_type_id;

          this.form.offer_deadline = this.moment(
            this.form.offer_deadline
          ).format("YYYY-MM-DD");

          resp.quotation_req_offer_product.forEach((x) => {
            let obj = [
              x.product_name,
              x.price,
              x.product_details,
              x.product_model_number,
              x.quantity,
              x.files,
              x.created_at,
              x.updated_at,
            ];

            this.offered.push(obj);
          });

          resp.quotation_req_offer_cost.forEach((x) => {
            let obj = [
              x.title,
              x.price,
              x.details,
              x.quantity,
              x.created_at,
              x.updated_at,
            ];

            this.cost.push(obj);
          });

          this.getCountry();
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getData(this.$route.params.id);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
